import { Flex, Text, Image } from '@chakra-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import { BlogPreviewPanel } from '../components/BlogPreviewPanel';
import { CarouselSlider } from '../components/CarouselSlider';
import { PageLayout } from '../components/pageLayout';
import { Section } from '../components/section';
import { VideoHero } from '../components/VideoHero';
import { GreyScaleImage } from '../components/GreyScaleImage';
import { Container } from '../components/Container';

export const query = graphql`
	query {
		prismic {
			# Fed into VideoHero
			allHomepages {
				edges {
					node {
						hero_text
						carousel {
							author
							body
							image
							imageSharp {
								id
								childImageSharp {
									fluid(maxHeight: 200, quality: 100) {
										base64
										originalImg
										originalName
										presentationWidth
									}
								}
							}
						}
						trusted_brands_logos {
							img
						}
						trusted_brands_heading
					}
				}
			}
			# Fed into BlogPreviewPanel
			allBlog_posts(first: 5) {
				edges {
					node {
						title
						cover_photo
						sub_title
						author
						_linkType
						_meta {
							uid
							lastPublicationDate
						}
					}
				}
			}
		}
	}
`;

const IndexPage = ({ data }) => {
	const homePage = data.prismic!.allHomepages!.edges[0]!.node;

	let carouselSlides = homePage.carousel;

	let heroText = homePage.hero_text[0]!.text || '';

	const blogPosts = data.prismic!.allBlog_posts!.edges;

	let transformedBlogPosts = blogPosts.map(({ node }) => ({
		author: node.author,
		bodyPreview: node.sub_title[0].text,
		img: node.cover_photo!.url,
		title: node.title[0].text,
		link: '/blog/' + node._meta.uid,
	}));

	let transformedCarouselSlides = carouselSlides.map((slide) => ({
		slideText: slide.body[0].text,
		author: slide.author,
		imgUrl: slide.image.url,
		imageSharp: slide.imageSharp,
	}));

	// console.log(transformedCarouselSlides);

	return (
		<PageLayout>
			<VideoHero text={heroText} />
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="center"
				overflowX="hidden"
			>
				{homePage.trusted_brands_heading || (
					<Container alignItems="center">
						<Text color="gray.400" fontSize="md" mb="24px">
							{homePage.trusted_brands_heading}
						</Text>
						<Flex
							justifyContent="center"
							mb="60px"
							w={{ base: '100%', md: '100%' }}
							flexWrap="wrap"
							alignItems="center"
						>
							{homePage.trusted_brands_logos!.map(({ img }, i) => {
								return (
									<GreyScaleImage height="49px" src={img.url} m={4} key={i} />
								);
							})}
						</Flex>
					</Container>
				)}
				<Container>
					<BlogPreviewPanel posts={transformedBlogPosts} />
				</Container>
				{carouselSlides || (
					<Container>
						<CarouselSlider slides={transformedCarouselSlides} />
					</Container>
				)}
			</Flex>
		</PageLayout>
	);
};

export default IndexPage;
