import React from 'react';
import { Flex, Image, Box, Text, Icon } from '@chakra-ui/core';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

interface IArrowIcon {
	left?: any;
	right?: any;
	disabled?: any;
}

// Single use Component for carousel arrows
const ArrowIcon = ({ left, right, disabled }: IArrowIcon) => {
	let name = left ? 'chevron-left' : 'chevron-right';
	right ? (name = 'chevron-right') : 'chevron-left';

	let opacity = disabled ? 0.8 : 1;

	return (
		<Icon
			name={name}
			size="30px"
			color="#4eb75d"
			// bg="#4444"
			// rounded="50%"
			cursor="pointer"
			opacity={opacity}
			// transform={`translateX(2rem)`}
			m={5}
		/>
	);
};

interface Slide {
	slideText: string;
	author?: string;
	imgUrl: string;
	imageSharp?: any;
}

interface ICarouselSlider {
	slides: Slide[];
}

export const CarouselSlider: React.FC<ICarouselSlider> = ({ slides }) => {
	return (
		<Box
			height="380px"
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexFlow: 'row',
				alignContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				// width: '1200px',
				width: 'calc(100% + 140px)',
				overflow: 'hide',
				maxWidth: '100vw',
			}}
		>
			{/* 

			See docs here:
			https://brainhubeu.github.io/react-carousel/docs/gettingStarted

			
			*/}
			<Carousel
				autoPlay={5000}
				animationSpeed={1500}
				infinite
				dots
				arrowLeft={<ArrowIcon left />}
				arrowLeftDisabled={<ArrowIcon left disabled />}
				arrowRight={<ArrowIcon right />}
				arrowRightDisabled={<ArrowIcon right disabled />}
				addArrowClickHandler
			>
				{slides &&
					slides.map((el, i) => {
						return (
							<Flex
								direction={{ base: 'column', md: 'row' }}
								key={i}
								mt={{ base: 12, md: 8 }}
							>
								<Image
									w={{ base: '100%', md: 1 / 2 }}
									my={{ md: 'auto' }}
									mb={{ base: 'auto', md: 'unset' }}
									src={el.imgUrl}
								/>
								<Flex
									w={{ base: '100%', md: 1 / 2 }}
									p={{ base: 2, md: 12 }}
									textAlign="left"
									ml={2}
									direction="column"
									justifyContent="center"
								>
									<Text fontSize="lg" height="min-content">
										{el.slideText}
									</Text>
									<Text fontSize="sm" mt={4} fontWeight="thin" color="#">
										{el.author}
									</Text>
								</Flex>
							</Flex>
						);
					})}
			</Carousel>
		</Box>
	);
};
