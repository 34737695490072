import React from 'react';
import {
	Flex,
	Link,
	Image,
	Heading,
	Text,
	Box,
	Icon,
	FlexProps,
	Stack,
	SimpleGrid,
} from '@chakra-ui/core';
import { Card } from './Card';

interface BlogItem {
	title?: string;
	author?: string;
	bodyPreview?: string;
	img?: string;
	link?: URL;
}

interface IBlogPreviewPanel {
	posts: BlogItem[];
	numberOfPosts?: number;
	rest: FlexProps;
}

export const BlogPreviewPanel = ({
	posts,
	numberOfPosts,
	...rest
}: IBlogPreviewPanel) => {
	return (
		<>
			<SimpleGrid
				columns={{ base: 1, md: 1, xl: 3, xxl: 6 }}
				spacing={{ base: 0, xl: 8 }}
				overflowX="scroll"
				width="100%"
				// overflow="visible"
			>
				{/* <Box bg="tomato" size="80px"></Box>
				<Box bg="tomato" size="80px"></Box>
				<Box bg="tomato" size="80px"></Box>
				<Box bg="tomato" size="80px"></Box>
				<Box bg="tomato" size="80px"></Box> */}
				{posts &&
					posts.map((blog, i) => {
						return (
							<Card
								key={blog.title + i}
								featureImageUrl={blog.img}
								title={blog.title}
								bodyText={blog.bodyPreview}
								ctaLink={blog.link}
								mb={{ base: 5, md: 8, xl: 8 }}
							/>
						);
					})}
			</SimpleGrid>
			{/* <Stack
				// flexDir={{ base: 'column', md: 'row', xl: 'row' }}
				isInline
				spacing={8}
				align="center"
				justify="center"
				p={4}
				w="100%"
				overflowX="scroll"
				// justifyContent="center"
				// alignItems="start"
				flexWrap="wrap" // add media query to make this only wrap on specific widths
				// flexWrap={{ base: 'wrap', md: 'wrap', xl: 'nowrap' }}
				{...rest}
			>
				{posts &&
					posts.map((blog, i) => {
						return (
							<Card
								key={blog.title + i}
								featureImageUrl={blog.img}
								title={blog.title}
								bodyText={blog.bodyPreview}
								ctaLink={blog.link}
								mb={{ base: 5, md: 8, xl: 8 }}
							/>
						);
					})}
			</Stack> */}
		</>
	);
};
