import { Icon, Image, ImageProps } from '@chakra-ui/core';
import styled from '@emotion/styled';
import React from 'react';

const StyledImage = styled(Image)`
	filter: grayscale(1) opacity(0.3);
`;

// interface IGreyScaleImage {
// 	text: string;
// 	props: LinkProps;
// }

export const GreyScaleImage = ({ ...rest }: { rest: ImageProps }) => {
	return <StyledImage {...rest} />;
};
