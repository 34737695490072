/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { Flex, Heading } from '@chakra-ui/core';

interface IVideoHero {
	text?: string;
}

export const VideoHero = ({ text }: IVideoHero) => {
	// console.log(text);
	return (
		<Flex textAlign="center" justifyContent="center" position="relative" mb="4">
			<Heading
				position="absolute"
				top="50%"
				width="470px"
				as="h1"
				size="2xl"
				zIndex="2"
				color="#222b45"
			>
				{text ? text : 'From farm to plate. Our family is yours '}
			</Heading>
			<video
				className="video-player"
				max-height="100vh"
				height="100%"
				width="100%"
				loop
				muted
				autoPlay
			>
				<source src="./video/bg-video.mp4" type="video/mp4" />
			</video>
			<div
				className="gradient"
				css={css`
					background-image: linear-gradient(
						to bottom,
						rgba(255, 255, 255, 0),
						#ffffff 98%
					);
					width: 100%;
					height: 102%;
					/* height: calc(100vh - 80px); */
					/* min-height: 600px; */
					object-fit: cover;
					position: absolute;
					top: 0px;
					left: 0px;
					z-index: 1;
				`}
			/>
		</Flex>
	);
};
